/* https://github.com/styled-components/styled-components/issues/1593 */

/* Had to create an external css file for @font-face declaration. @font-face rules were set inside of createGlobalStyle and it seems that it if you have dynamic style changes based on props your fonts will get re-requested which causes a flicker. This way fonts are requested once.  */

@font-face {
  font-family: "ChronicleDisplay";
  src: url(./fonts/ChronicleDisplay-Semi.woff2) format("woff2"),
    url(./fonts/ChronicleDisplay-Semi.woff) format("woff"),
    url(./fonts/ChronicleDisplay-Semi.ttf) format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whitney";
  src: url(./fonts/Whitney-Book.woff2) format("woff2"),
    url(./fonts/Whitney-Book.woff) format("woff"),
    url(./fonts/Whitney-Book.ttf) format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Whitney";
  src: url(./fonts/Whitney-Medium.woff2) format("woff2"),
    url(./fonts/Whitney-Medium.woff) format("woff"),
    url(./fonts/Whitney-Medium.ttf) format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cadiz";
  src: url(./fonts/CadizWeb-Book.woff2) format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cadiz";
  src: url(./fonts/Cadiz-RegularItalic.otf) format("opentype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Cadiz";
  src: url(./fonts/Cadiz-RegularItalic.otf) format("opentype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "CadizBlack";
  src: url(./fonts/CadizWeb-Black.woff2) format("opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CadizBlackItalic";
  src: url(./fonts/Cadiz-BlackItalic.otf) format("opentype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
/* i svg, i img { display: none; } */
